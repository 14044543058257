<template>
  <v-container>
    <!-- pageHeader -->
    <v-row>
      <v-col cols="8">
        <h1>Messages for {{ userAccount.email }}</h1>
      </v-col>
      <v-col cols="4" align-self="center" align="end">
        <v-btn
          to="/messages"
          color="primary"
          depressed
          exact
          :loading="loading"
          :disabled="loading"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          back to messages</v-btn
        >
      </v-col>
    </v-row>
    <!-- pageHeader -->

    <!-- timline/messages -->
    <v-row>
      <v-col cols="12">
        <v-card outlined :loading="loading">
          <v-card-text class="text--primary">
            <v-timeline>
              <v-timeline-item
                v-for="(item, index) in timeline"
                :key="index"
                :icon="item.icon"
                :color="item.color"
              >
                <v-card
                  elevation="1"
                  :outlined="$vuetify.theme.dark ? true : false"
                  :color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                >
                  <v-card-title class="headline">
                    {{ item.title }}
                  </v-card-title>
                  <v-card-text class="text--primary">
                    <p>
                      Date:
                      {{ formatDate(item.dateCreated) }}
                    </p>
                    <p v-if="item.message">Message: {{ item.message }}</p>
                  </v-card-text>
                  <v-card-actions
                    v-if="item.message.includes('purchase')"
                    class="d-none"
                  >
                    <v-btn
                      text
                      :loading="loading"
                      :disabled="loading"
                      color="error"
                      class="ml-auto mt-4 d-block"
                      >Email Your Travel Details to Yourself</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
          <!-- add message button -->
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              :disabled="loading"
              @click="
                $vuetify.goTo('#scrollTo');
                addingMessage = !addingMessage;
              "
            >
              <v-icon left>mdi-plus-circle-outline</v-icon>
              Add New Message</v-btn
            >
          </v-card-actions>
          <!-- add message button -->
          <!-- add message form -->
          <v-expand-transition>
            <v-card-text v-if="addingMessage">
              <v-row>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                  <v-card outlined :loading="loading">
                    <v-card-text class="text--primary">
                      <h3 class="mb-4">Add Message</h3>
                      <v-form ref="newMessageForm">
                        <v-textarea
                          dense
                          outlined
                          :rows="8"
                          v-model.trim="message.message"
                          :loading="loading"
                          :disabled="loading"
                          label="Message Content *"
                          :rules="[(v) => !!v || 'Field Required']"
                        ></v-textarea>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="error"
                        outlined
                        @click="addingMessage = false"
                      >
                        Cancel
                      </v-btn>
                      <v-spacer />
                      <v-btn color="primary" outlined @click="onAddNewMessage">
                        <v-icon left>mdi-check</v-icon>
                        Submit New Message</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-expand-transition>
          <!-- add message form -->
        </v-card>
      </v-col>
      <v-col id="scrollTo"></v-col>
    </v-row>
    <!-- timline/messages -->
  </v-container>
</template>

<script>
import { portalsApp } from "@/firebase";
import formatDate from "@/mixins/formatDate";
import axios from "axios";
export default {
  mixins: [formatDate],
  data: () => ({
    userAccount: "",
    addingMessage: false,
    message: {
      adminActionRequired: false,
      archived: false,
      dateCreated: new Date().toISOString().substring(0, 10),
      from: "admin",
      message: "",
      readByAdmin: true,
      repliedToByAdmin: true,
      senderActionRequired: false,
      title: "We sent you a message",
      to: "",
      icon: "mdi-message-reply-text-outline",
      color: "primary",
    },
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    userId() {
      return this.$route.params.id;
    },
    timeline() {
      return this.userAccount.timeline;
    },
  },
  methods: {
    async onAddNewMessage() {
      if (!this.$refs.newMessageForm.validate()) return;
      if (!this.message.message.length) return;
      await this.$store.dispatch("setLoading", true);
      this.message.to = this.userAccount.email;
      this.timeline.push(this.message);

      this.timeline.forEach((i) => {
        i.readByAdmin = true;
        i.adminActionRequired = false;
        i.repliedToByAdmin = true;
      });

      let userAccount = portalsApp
        .firestore()
        .collection("userAccounts")
        .doc(this.userId);

      await userAccount.update({
        timeline: this.timeline,
      });

      await axios.post("/api/new-dashboard-message", {
        email: this.userAccount.email,
        secondaryEmail: this.userAccount.secondaryEmail,
      });

      this.$refs.newMessageForm.reset();
      this.addingMessage = false;

      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Message sent successfully",
        timeout: 5000,
      });

      await this.$store.dispatch("setLoading", false);
      this.$router.push("/messages");
    },
  },
  watch: {
    userId: {
      immediate: true,
      async handler(id) {
        if (!id) return;
        await this.$bind(
          "userAccount",
          portalsApp.firestore().collection("userAccounts").doc(id)
        );
      },
    },
  },
};
</script>

<style>
</style>